const triggerForm = (event) => {
  const value = event.currentTarget.value;
  let url = window.location.href.split(/[?&]/)[0]
  if (value) {
    url += '?state=' + value;
  }
  window.location.href = url;
}

const bindGetRecords = (element) => {
  element.addEventListener('change', triggerForm);
};

function initFilters() {
  const select = document.querySelectorAll('#request-filter-status');
  Array.prototype.forEach.call (select, bindGetRecords);
}

export { initFilters }
