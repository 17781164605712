function autocomplete(id) {
  const element = document.getElementById(id);
  if (element) {
    const autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
    google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
    google.maps.event.addDomListener(element, 'keydown', function(e) {
      if (element.value == "") {
        reset_hidden_field_input();
      }
      if (e.key === "Enter") {
        e.preventDefault(); // Do not submit the form on Enter.
      }
    });
  }
}

function reset_hidden_field_input(types = []) {
  const ids = ["street_number", "route", "locality", "postal_code"];
  const emptyIds = ids.filter(id => !types.includes(id));
  emptyIds.forEach(id => document.getElementById(id).value = "");
}

function onPlaceChanged() { 
  let types = new Array();
  const place = this.getPlace(); 
  const longitude = document.getElementById('location_lng');
  if (longitude) { longitude.value = place.geometry.location.lng() }
  const latitude = document.getElementById('location_lat');
  if (latitude) { latitude.value = place.geometry.location.lat() }
  for (var i in place.address_components) {
    let component = place.address_components[i];
    for (var j in component.types) {  // Some types are ["country", "political"]
      let elementType = document.getElementById(component.types[j]);
      let type = component.types[j];
      if (elementType) {
        let name = (type == "country" ? component.short_name : component.long_name);
        elementType.value = name;
        types.push(component.types[j]);
      }
    }
  }
  reset_hidden_field_input(types);
}

function initAutocomplete() {
  google.maps.event.addDomListener(window, 'load', function() {
    autocomplete('user_input_autocomplete_address');
    autocomplete('request_input_autocomplete_address');
    autocomplete('teacher_input_autocomplete_address');
    autocomplete('client_input_autocomplete_address');
    autocomplete('teacher_map_input_autocomplete_address');
    autocomplete('teacher_input_autocomplete_address');
  });
}

export { initAutocomplete }
