import * as typeformEmbed from '@typeform/embed'

function mountTypeform(element) {
  const dataset = element.dataset;
  const formId = dataset.form;

  const domain = `https://mymentor-survey.typeform.com/to/${formId}?`;
  let params = ``;
  for( const d in dataset) {
    if (d=='form') { continue; }
    params += `${d}=${dataset[d]}&`
  }
  const url = `${domain}?${params}`;
  typeformEmbed.makeWidget(element, url, {
    hideFooter: true,
    hideHeaders: true,
    opacity: 0
  });
}

function initMountTypeform() {
  window.addEventListener("DOMContentLoaded", function(){
    const form = document.getElementById("typeform");
    if (form) { mountTypeform(form) };
  })
}

export { initMountTypeform }
