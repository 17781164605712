function push_array(inp) {
  let arr = [];
  for(let i=0; i < inp.length; i++) {
    arr.push(inp[i].dataset.day + '-' + inp[i].dataset.slot);
  }
  return arr;
}

function sort_slots(yo, arr, selector) {
  const new_slot = yo.dataset.day + '-' + yo.dataset.slot;
  selector.value = (sort_days(arr));
}

function sort_days(arr) {
  return arr.sort().join('#');
}

const toggleSelected = (event) => { 
  event.currentTarget.classList.toggle('selected');
  const form = event.currentTarget.closest('form');
  const inp = form.getElementsByClassName('selected');
  const selector = form.querySelector('.availabilities[data-selectable="true"]');
  sort_slots(event.currentTarget, push_array(inp), selector);
};

const bindToggleSelected = (element) => {
  element.addEventListener('click', toggleSelected);
};

function initToggleSelected() {
  const calendarCell = document.querySelectorAll('.availabilities_cal tbody td');
  Array.prototype.forEach.call (calendarCell, bindToggleSelected);
  const answerCell = document.querySelectorAll('.availabilities_list .slot');
  Array.prototype.forEach.call (answerCell, bindToggleSelected);
}
export { initToggleSelected }
