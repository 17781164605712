import { fetchLevel } from './initFetchLevels';

const addSingleInput = (e) => {
  const index = document.querySelectorAll(`.user_teachers_teachings_id_${e}`).length;
  const origin = document.querySelector(`.user_teachers_teachings_id_${e}:last-child`);
  const clone = origin.cloneNode(true);
  const select = clone.querySelector('select');
  const option = document.createElement('option');
  option.selected = true;
  select.setAttribute('name', `user[teachers_teachings_attributes][${index}][id_${e}]`);
  select.setAttribute('id', `${e}_${index}`);
  select.insertBefore(option, select.querySelector('optgroup:first-child'));
  if (clone.querySelector('label')) clone.querySelector('label').remove();
  origin.parentNode.appendChild(clone);
  if (select.classList.contains('select_field')) {
    select.addEventListener('change', fetchLevel);
  }
}

const addPairOfInputs = (event) => {
  event.preventDefault();
  Array.prototype.forEach.call (['field', 'level'], addSingleInput);
};

function initAddFieldInput() {
  const btn = document.getElementById('add-field-button');
  if (btn) { btn.addEventListener('click', addPairOfInputs); }
}

export { initAddFieldInput }
