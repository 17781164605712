const nextPage = (event) => {
  const currentEl = event.currentTarget.closest('section');
  const index =  Number(currentEl.dataset.index) + 1 ;
  let nextEl = document.querySelector(`section.tutorial[data-index="${index}"]`);
  if (nextEl) {
    [currentEl, nextEl].forEach(e => e.classList.toggle('hide'));
  }

}

const bindNextPage = (element) => {
  element.addEventListener('click', nextPage);
};

function initPagination() {
  const buttons = document.querySelectorAll('.pages-onboarding article .btn');
  Array.prototype.forEach.call(buttons, bindNextPage);
}

export { initPagination }
