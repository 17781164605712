const checkPhoneInput = (event) => {
  const regExp = /^0[0-9]{9}$/;
  const matches = event.currentTarget.value.match(regExp);
  if (matches) {
    document.querySelectorAll('#lead-form .phone i').forEach(function(element) {
      element.remove();
    });
    const pic = document.createElement("i");
    pic.classList.add("fas", "fa-check");
    event.currentTarget.parentNode.prepend(pic);
    event.currentTarget.parentNode.classList.remove('error');
  }
  else {
    const i = event.currentTarget.parentNode.querySelector('i');
    if (i) { i.remove(); }
  }
}

function initControlForm() {
  const phoneInput = document.getElementById('phone');
  if (phoneInput) { phoneInput.addEventListener('keyup', checkPhoneInput); }
}

export { initControlForm }
