const openCardModal = (event) => {
  const modal = document.getElementById('new-card-modal');
  modal.classList.add('show');
  modal.classList.remove('hide');
};
const openScoreModal = (event) => {
  const modal = document.getElementById('score-modal');
  modal.classList.remove('hide');
  modal.classList.add('show');
};
const openAdminModal = (event) => {
  const modal = document.getElementById('modal-admin');
  modal.classList.add('show', 'in');
  modal.classList.remove('hide');
};
const openPrestaModal = (event) => {
  const modal = document.getElementById('prestation-modal');
  modal.classList.add('show', 'in');
  modal.classList.remove('hide');
};
const bindCloseModal = (element) => {
  element.addEventListener('click', closeModal);
};
const closeModal = (event) => {
  const modal = event.currentTarget.closest('.modal');
  modal.classList.remove('show');
  modal.classList.add('hide');
};
const observer = new MutationObserver(function(mutationsList){
  for(var mutation of mutationsList) {
    if (mutation.type == 'childList') {
        initModal();
    }
  }
});
function initModal() {
  const modalSkill = document.getElementById('modal-admin');
  if (modalSkill) { observer.observe(modalSkill, { childList: true }) };
  const modalFront = document.getElementById('modal-front');
  if (modalFront) { observer.observe(modalFront, { childList : true}) };
  const openCardBtn = document.getElementById('open-new-card-modal');
  if (openCardBtn) openCardBtn.addEventListener('click', openCardModal);
  const openScoreBtn = document.getElementById('open-score-modal');
  if (openScoreBtn) openScoreBtn.addEventListener('click', openScoreModal);
  const openSkillBtn = document.getElementById('open-skill-modal');
  if (openSkillBtn) openSkillBtn.addEventListener('click', openAdminModal);
  const openStudentBtn = document.getElementById('open-student-modal');
  if (openStudentBtn) openStudentBtn.addEventListener('click', openAdminModal);
  const openEditStudentBtns = document.querySelectorAll(".open-students-modal");
  Array.prototype.forEach.call(openEditStudentBtns, (openEditStudentBtn) => {
    openEditStudentBtn.addEventListener('click', openAdminModal);
  });
  const openPrestaBtn = document.getElementById('open-prestation-modal');
  if (openPrestaBtn) openPrestaBtn.addEventListener('click', openPrestaModal);
  // Enable for auto-prestation update
  //
  // const editPrestations = document.querySelectorAll(".edit_prestation");
  // Array.prototype.forEach.call(editPrestations, (editPrestation) => {
  //   editPrestation.addEventListener('click', openPrestaModal)
  // });
  const openSchoolingBtn = document.getElementById('open-schooling-modal');
  if (openSchoolingBtn) openSchoolingBtn.addEventListener('click', openAdminModal);
  const closeBtns = document.querySelectorAll('.modal button.close, .modal .cancel');
  Array.prototype.forEach.call(closeBtns, bindCloseModal);
}

// L'observation peut être arrêtée par la suite
//observer.disconnect();
export { initModal }
