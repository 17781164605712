import axios from 'axios';

function generateStars(times, nb_stars) {
  let string = "";
  for (let i = 1; i <= nb_stars; i++) {
    if (i <= times) {
      string += '<i class="fas fa-star colored" id="' + i + '"></i>';
    } else {
      string += '<i class="far fa-star" id="' + i + '"></i>';
    }
  }
  return string
}

function stringifyData(inputs) {
  let data = "";
  for(let i=0; i<inputs.length; i++ ){ 
    data += inputs[i].getAttribute('name') + "=" + inputs[i].getAttribute('value') +"&";
  }
  return data
}

const updatePrestation = (event) => {
  const score = parseInt(event.currentTarget.getAttribute("id"));
  const nbStars = document.querySelectorAll('.stars i.fa-star').length;
  document.getElementById('prestation_grade').setAttribute('value', parseInt(score*2, 10));
  const form = document.querySelector("form.edit_prestation");
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  const url = form.getAttribute('action');
  const options = {
    method: 'PATCH',
    headers: {
      'X-CSRF-Token': csrfToken.getAttribute('content'),
      'Accept': 'application/javascript',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: encodeURI(stringifyData(form.elements)),
    url,
  };
  axios(options)
    .then((data) => {
      document.querySelector('.stars').innerHTML = generateStars(score, nbStars);
      const stars = document.querySelectorAll('.stars i.fa-star');
      Array.prototype.forEach.call(stars, bindUpdatePrestation);
    });
}
    
const bindUpdatePrestation = (element) => {
  element.addEventListener('click', updatePrestation);
}

function initFeedback() {
  const stars = document.querySelectorAll('.stars i.fa-star');
  Array.prototype.forEach.call (stars, bindUpdatePrestation);
}

export { initFeedback }