function disabling() {
  const submit = event.currentTarget.querySelector('input[type=submit]');
  submit.disabled = true;
}

const bindDisabling = (element) => {
  element.addEventListener('submit', disabling);
}

function initPrestationButton() {
  const prestations = document.querySelectorAll('#prestations form.edit_prestation');
  Array.prototype.forEach.call(prestations, bindDisabling);
}

export { initPrestationButton }
