import axios from 'axios';

var markerClusterer;
var imageUrl = 'http://chart.apis.google.com/chart?cht=mm&chs=24x32&' +
    'chco=FFFFFF,008CFF,000000&ext=.png';
var map;

function loadMarkers(teachers) {
  if (markerClusterer) {
    markerClusterer.clearMarkers();
  }

  var markerImage = new google.maps.MarkerImage(imageUrl,
    new google.maps.Size(24, 32)
  );

  markerClusterer = new MarkerClusterer(map, teachers, {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
  });
}

function getMapRange(map) {
  const south = map.getBounds().getSouthWest().toJSON();
  const north = map.getBounds().getNorthEast().toJSON();
  const range = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(north.lat, north.lng), new google.maps.LatLng(south.lat, south.lng)) / 1000;
  return range;
}

function initMap() {
  if (document.getElementById('map')) {
    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 11,
      center: {lat: 48.866667, lng: 2.333333},
    });
    google.maps.event.addListener(map, 'idle', function() { 
      const range = getMapRange(map);
      const location = map.getCenter().toJSON();
      fetch_teachers(location, range);
    });
    const submit = document.querySelector('#teachers input[type=submit]');
    google.maps.event.addDomListener(submit, 'click', function(e) {
      e.preventDefault();
      const lat = document.getElementById('location_lat').value;
      const lng = document.getElementById('location_lng').value;
      const location = {"lat": parseFloat(lat), "lng": parseFloat(lng) };
      const range = getMapRange(map);
      fetch_teachers(location, range);
      if (location.lat && location.lng) { map.setCenter(location) };
    });
  }
}

function fetch_teachers(location, range) {
  const field = document.getElementById('field_0').value;
  const level = document.getElementById('level_0').value;
  const params = {
    "teaching": {
      "id_field": field,
      "id_level": level
    },
    "location": location,
    "range": range
  }
  axios.post('/api/v1/teachers', params)
    .then(result => { return result.data })
    .then((data) => {
      const teachers = data.map(function(teacher) {
        return new google.maps.Marker({
          position: new google.maps.LatLng(teacher.latitude, teacher.longitude),
        });
      });
    loadMarkers(teachers);
  })

}


export { initMap }
