const displayDisclaimer = (event) => {
  const alert = document.getElementById('score_disclaimer');
  const option = event.currentTarget[event.currentTarget.selectedIndex].text;
  if (option.match(/soutien/i)) {
    alert.style.display = 'block';
  } else {
    alert.style.display = 'none';
  }
};

function initScoreForm() {
  const input = document.getElementById('score_request_id');
  if (input) input.addEventListener('change', displayDisclaimer);
}

export { initScoreForm }
