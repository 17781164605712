const toggleCouponInput = (event) => {
  const couponForm = document.getElementById('coupon-form');
  couponForm.classList.toggle('hide');
}

function initCouponForm() {
  const linkCoupon = document.getElementById('add-coupon-code');
  if (linkCoupon) { linkCoupon.addEventListener('click', toggleCouponInput); }
}

export { initCouponForm }
