import axios from 'axios';
import debounce from 'lodash/debounce';

const handleAutocomplete = (event) => {
  const event_currentTarget = event.target;
  const val = event_currentTarget.value;
  removeLists(event_currentTarget);

  if (val && val.length >= 3) {
    // TODO container should be created in the function of the promise
    const resultsList = document.createElement("div");
    resultsList.setAttribute("class", "autocomplete-items");
    event_currentTarget.parentNode.appendChild(resultsList);
    axios.get(`/api/v1/institutions?s=${val}&limit=10`)
      .then((result) => {
        return result.data;
      })
      .then((data) => {
        data.forEach(i => createSchoolItem(i, resultsList));
      })
  } else {
    const input = event_currentTarget.closest('.schooling').querySelector('.form-control[type=hidden]');
    input.removeAttribute("value");
  }
};

const createSchoolItem = (i, s) => {
  const listItem = document.createElement("div");
  let str = i.main_name;
  if (i.university) {
    str = `${i.university} - ${i.main_name}`;
  } else if(i.abbr != null && !i.main_name.includes(i.abbr)) {
    str = `${i.abbr} - ${i.main_name}`;
  }
  const text = document.createTextNode(str);
  const strong = document.createElement("strong");
  const span = document.createElement("span");
  const address = document.createTextNode(`${i.address.street_number == null ? "" : i.address.street_number} ${i.address.street}, ${i.address.city}`);
  strong.dataset.id = i.id;
  strong.appendChild(text);
  span.appendChild(address);
  listItem.appendChild(strong);
  listItem.appendChild(document.createElement("br"));
  listItem.appendChild(span);
  listItem.addEventListener("click", selectResult);
  s.appendChild(listItem);
}

const selectResult = (e) => {
  const container = event.currentTarget.closest('.schooling');

  const originText = container.querySelector('.form-control[type=text]');
  originText.value = event.currentTarget.querySelector('strong').textContent;
  const originHidden = container.querySelector('.form-control[type=hidden]');
  originHidden.value = event.currentTarget.querySelector('strong').dataset.id;

  removeLists(e.currentTarget);
  if (document.querySelector(".autocomplete-items")) {
    document.querySelector(".autocomplete-items").remove();
  }
}

function removeLists(ele) {
  let x = document.getElementsByClassName("autocomplete-items");
  for (let i = 0; i < x.length; i++) {
    if (ele != x[i]) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
};

const removeResultsList = (event) => {
  if (event.srcElement.closest(".autocomplete-items") == null) {
    const resultsList = event.currentTarget.querySelector('.autocomplete-items');
    if (resultsList) {
      resultsList.classList.add('hidden');
    }
  }
};

const bindRemoveResultsList = (element) => {
  element.closest('body').addEventListener('click', removeResultsList);
}

const bindhandleAutocomplete = (element) => {
  element.addEventListener('input', debounce(handleAutocomplete, 500));
};

const observer = new MutationObserver(function (mutationsList) {
  for (var mutation of mutationsList) {
    if (mutation.type == 'childList') {
      initSchoolingsAutocomplete();
    }
  }
});

function initSchoolingsAutocomplete() {
  const autocompleteAdmin = document.getElementById('modal-admin');
  if (autocompleteAdmin) { observer.observe(autocompleteAdmin, { childList: true }) };
  const inputs = document.querySelectorAll(".schooling-input");
  Array.prototype.forEach.call(inputs, bindhandleAutocomplete);
  const elements = document.querySelectorAll('.autocomplete');
  elements.forEach(bindRemoveResultsList);
}

export {
  initSchoolingsAutocomplete
}
