import axios from 'axios';

function generateClasses(json) {
  const cycles = {};
  json.forEach( function(object) {
    if (cycles.hasOwnProperty(object['cycle'])) {
      cycles[object['cycle']].push(object);
    } else {
      cycles[object['cycle']] = [object];
    };
  });
  return cycles;
};

function replaceSelectOptions(cycles, select) {
  select.innerHTML = '';
  Object.keys(cycles).forEach( function(key) {
    const optgroup = document.createElement('optgroup');
    optgroup.label = key;
    select.appendChild(optgroup);
    cycles[key].forEach( function(classe) {
      const option = document.createElement('option');
      option.value = classe.id;
      option.innerHTML = classe.name;
      optgroup.appendChild(option);
    });
  });
}

const fetchLevel = (event) => {
  const field = event.currentTarget.value;
  const id = event.currentTarget.id.match(/\d+/)[0];
  const level = document.getElementById(`level_${id}`);
  let url = window.location.origin;
  url += `/api/v1/fields/${field}/levels`;
  axios.get(url)
    .then(response => response.data)
    .then((data) => {
      const cycles = generateClasses(data);
      replaceSelectOptions(cycles, level);
    });
};

const bindFetchLevel = (element) => {
  element.addEventListener('change', fetchLevel);
};

const observer = new MutationObserver(function(mutationsList){
  for(var mutation of mutationsList) {
    if (mutation.type == 'childList') {
      initFetchLevels();
    }
  }
});

function initFetchLevels() {
  const modalSkill = document.getElementById('modal-admin');
  if (modalSkill) { observer.observe(modalSkill, { childList: true }) };
  const select_field = document.querySelectorAll('.select_field');
  Array.prototype.forEach.call (select_field, bindFetchLevel);
}


export { initFetchLevels, fetchLevel }
