const charCounter = (event) => {
  const length = event.currentTarget.value.length;
  const min = event.currentTarget.dataset.length_min;
  const max = event.currentTarget.dataset.length_max;
  const parent = event.currentTarget.closest(".char-counter");

  if (length < min || length > max) {
    setcounter(parent, "red", `${length} / ${length < min ? min : max}`, true);
  } else {
    setcounter(parent, "green", `${length} / ${max}`, false);
  }
};

const setcounter = (parent, color, text, state) => {
    const button = parent.querySelector(".btn");
    const counter = parent.querySelector(".counter");
    counter.style.color = color;
    counter.innerHTML = text;
    button.disabled = state;
};

const bindCharCounter = (element) => {
  element.addEventListener('input', charCounter);
};

function initCharCounter() {
  const inputs = document.querySelectorAll(".presentation");
  Array.prototype.forEach.call(inputs, bindCharCounter);
}

export { initCharCounter }
