/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images', true);

import('css/application.css');
import { initAutocomplete } from './initAutocomplete';
import { initControlForm } from './initControlForm';
import { initCouponForm } from './initCouponForm';
import { initToggleForm} from './initToggleForm';
import { initFeedback } from './initFeedback';
import { initFetchLevels } from './initFetchLevels';
import { initFilters } from './initFilters';
import { initScoreForm } from './initScoreForm';
import { initToggleSelected } from './initToggleSelected';
import { initAddFieldInput } from './initAddFieldInput';
import { initModal } from './initModal';
import { initMap } from './initMap';
import { initMountTypeform } from './mountTypeform';
import { initPagination } from './initPagination';
import { initSchoolingsAutocomplete } from './initSchoolingsAutocomplete.js';
import { initCharCounter } from './initCharCounter';
import { initPrestationButton } from './initPrestationButton';

document.addEventListener("DOMContentLoaded",()=>{
  initAddFieldInput();
  initAutocomplete();
  initControlForm();
  initCouponForm();
  initToggleForm();
  initFeedback();
  initFetchLevels();
  initFilters();
  initMap();
  initModal();
  initMountTypeform();
  initPagination();
  initScoreForm();
  initToggleSelected();
  initSchoolingsAutocomplete();
  initCharCounter();
  initPrestationButton();
});
