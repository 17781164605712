function toggleElement(element) {
  if (element.classList.contains('hidden')) {
    element.classList.remove('hidden');
  } else {
    element.classList.add('hidden');
  }
}

const hideElement = (element) => {
  element.classList.add('hidden');
}

const toggleEditForm = (event) => {
  const editForm = document.getElementById('admin-edit-form');
  toggleElement(editForm);
}

const toggleBillForm = (event) => {
  const billsClientForm = document.getElementById('admin-bill-client-form');
  toggleElement(billsClientForm);
  const clientForm = document.getElementById('edit-form-btn');
  toggleElement(clientForm);
}

function initToggleForm() {
  const elementToHide = document.querySelectorAll('#admin-edit-form, #admin-bill-client-form');
  if (elementToHide && elementToHide.length > 0) {
    Array.prototype.forEach.call(elementToHide, hideElement);
  }
  const button = document.getElementById('edit-form-btn');
  if (button) { button.addEventListener('click', toggleEditForm); }
  const billForm = document.getElementById('new-bill-client-btn');
  if (billForm) { billForm.addEventListener('click', toggleBillForm); }
}

export { initToggleForm }
